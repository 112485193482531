// CardGrid.jsx
import React, { useState } from 'react';
import Card from './Card';

const CardGrid = () => {
  const [cards, setCards] = useState([
    { id: 1, title: 'Card 1', description: 'This is card 1.' },
    { id: 2, title: 'Card 2', description: 'This is card 2.' },
    { id: 3, title: 'Card 3', description: 'This is card 3.' },
    { id: 4, title: 'Card 4', description: 'This is card 4.' },
  ]);

  const handleDelete = (id) => {
    const updatedCards = cards.filter((card) => card.id !== id);
    setCards(updatedCards);
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 p-10">
      {cards.map((card) => (
        <Card 
          key={card.id} 
          title={card.title} 
          description={card.description} 
          onDelete={() => handleDelete(card.id)} 
        />
      ))}
    </div>
  );
};

export default CardGrid;
