import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const LandingPage = ({ getStart, setGetStart }) => {
  const handleGetStarted = () => {
    setGetStart(!getStart);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 to-purple-600 py-10">
      {/* Main Content */}
      <div className="bg-white rounded-lg shadow-xl p-10 max-w-lg mx-auto text-center">
        <h2 className="text-5xl font-extrabold text-gray-800 mb-5">
          Welcome to Your Storage App!
        </h2>
        <p className="text-lg text-gray-600 mb-6">
          Effortlessly manage your files with our integrated calculator to help you optimize your storage space.
        </p>
        <button
          onClick={handleGetStarted}
          className="bg-blue-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition-all duration-300 ease-in-out transform hover:scale-105"
        >
          Get Started
        </button>
      </div>

      {/* Features Section */}
      <section className="py-10 max-w-6xl mx-auto">
        <h2 className="text-4xl font-semibold text-center text-white mb-8">Features</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          <div className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center transition-transform duration-300 hover:shadow-xl hover:scale-105">
            <h3 className="text-xl font-semibold mb-2">Smart Storage Management</h3>
            <p className="text-gray-600 text-center mb-4">
              Organize and manage your files with ease.
            </p>
            <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 text-3xl" />
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center transition-transform duration-300 hover:shadow-xl hover:scale-105">
            <h3 className="text-xl font-semibold mb-2">Integrated Calculator</h3>
            <p className="text-gray-600 text-center mb-4">
              Keep track of your storage space requirements efficiently.
            </p>
            <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 text-3xl" />
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center transition-transform duration-300 hover:shadow-xl hover:scale-105">
            <h3 className="text-xl font-semibold mb-2">Interactive Card View</h3>
            <p className="text-gray-600 text-center mb-4">
              Visualize your data with an engaging grid layout.
            </p>
            <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 text-3xl" />
          </div>
          {/* Add more features as needed */}
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
