// Card.jsx
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faTrash } from '@fortawesome/free-solid-svg-icons';

const Card = ({ title, description, onDelete }) => {
  const [liked, setLiked] = useState(false);

  const handleLike = () => setLiked(!liked);

  return (
    <div className="bg-white shadow-lg rounded-lg p-5 hover:shadow-xl transition-shadow">
      <h2 className="text-xl font-semibold mb-2">{title}</h2>
      <p className="text-gray-600 mb-4">{description}</p>
      <div className="flex justify-between">
        <button 
          onClick={handleLike} 
          className={`text-${liked ? 'red' : 'gray'}-500 hover:text-red-600`}
        >
          <FontAwesomeIcon icon={faHeart} />
        </button>
        <button 
          onClick={onDelete} 
          className="text-gray-500 hover:text-red-600"
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    </div>
  );
};

export default Card;
