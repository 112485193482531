import './App.css';
import Calculator from './Calculator';
import { useState } from 'react';
import CardGrid from './CardGrid';
import LandingPage from './LandingPage';
import Layout from './Layout';

function App() {
  const [toggleCalc, setToggle] = useState(false);
  const [getStart, setGetStart] = useState(false);

  return (
    <Layout>
      {getStart ? <div className={`grid ${toggleCalc ? 'grid-cols-1 lg:grid-cols-2 gap-0':'grid-cols-1'} bg-gray-200`}>
      {toggleCalc && <Calculator />}
        <div className={`relative ${toggleCalc ? 'w-full' : 'w-screen'} h-screen bg-gray-100`}>
          <CardGrid />
          <span onClick={() => { setToggle(!toggleCalc) }} title='Show Calculator' className='absolute top-4 right-4 w-4 h-4 bg-gray-800 rounded hover:bg-blue-500'></span>
        </div>
      </div> : <LandingPage getStart={getStart} setGetStart={setGetStart} />
      }
    </Layout>
  );
}

export default App;
