// HistoryEntry.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faBookmark } from '@fortawesome/free-solid-svg-icons';

const HistoryEntry = ({ entry, onDelete, onSave }) => {
    const item=entry;
  return (
    <li className="border-b border-gray-300 py-2 flex justify-between items-center">
      <div>
        <span className="font-mono">{entry.calculation}</span>
        <span className="font-semibold"> = {entry.result}</span>
      </div>
      <div className="flex space-x-4">
        <button
          onClick={() => onSave(item)}
          className="text-blue-600 hover:text-blue-800"
        >
          <FontAwesomeIcon icon={faBookmark} />
        </button>
        <button
          onClick={() => onDelete(item)}
          className="text-red-600 hover:text-red-800"
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    </li>
  );
};

export default HistoryEntry;
