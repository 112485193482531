import React, { useState, useEffect, useCallback } from 'react';
import HistoryEntry from './HistoryEntry';
import { create, all } from 'mathjs';

const math = create(all);

const Button = ({ onClick, children, className }) => (
  <button onClick={onClick} className={`bg-blue-500 text-white p-4 rounded hover:bg-blue-600 ${className}`}>
    {children}
  </button>
);

const Calculator = () => {
  const [input, setInput] = useState('');
  const [history, setHistory] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const savedHistory = localStorage.getItem('calculatorHistory');
    if (savedHistory) {
      setHistory(JSON.parse(savedHistory));
    }
  }, []);

  // Wrap handleCalculate with useCallback to memoize it
  const handleCalculate = useCallback(() => {
    try {
      const result = math.evaluate(input);
      if (typeof result === 'number') {
        const newHistory = [...history, { calculation: input, result }];
        setHistory(newHistory);
        localStorage.setItem('calculatorHistory', JSON.stringify(newHistory));
        setInput(result.toString());
        setError(null);
      }
    } catch {
      setError('Invalid calculation');
    }
  }, [input, history]); // Add input and history as dependencies

  useEffect(() => {
    const handleKeyPress = (event) => {
      const key = event.key;
      if (!isNaN(key) || ['+', '-', '*', '/'].includes(key)) {
        handleButtonClick(key);
      } else if (key === 'Enter') {
        handleCalculate();
      } else if (key === 'Backspace') {
        handleDelete();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleCalculate]); // Now include handleCalculate in dependencies

  const handleButtonClick = (value) => {
    setInput((prev) => prev + value);
  };

  const handleClear = () => {
    setInput('');
    setError(null);
  };

  const handleDelete = () => {
    setInput((prev) => prev.slice(0, -1));
  };

  const handleItemDelete = (item) => {
    console.log('Delete:', item);
  };

  const handleItemSave = (item) => {
    console.log('Saved:', item);
  };

  const handlePaste = (event) => {
    event.preventDefault(); // Prevent the default paste behavior
    const pastedData = event.clipboardData.getData('text'); // Get the pasted text
    if (/^[\d\s+\-*/().]+$/.test(pastedData)) { // Basic validation to allow only numbers and operators
      setInput((prev) => prev + pastedData);
    } else {
      alert('Invalid paste data. Please paste a valid expression.');
    }
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 p-10 bg-gray-100">
      <div className="bg-white p-5 rounded-lg shadow-lg w-80 h-fit">
        <div className="flex flex-col mb-4">
          <input
            type="text"
            value={input}
            readOnly
            onPaste={handlePaste} // Attach the onPaste event handler
            className="border border-gray-300 rounded p-2 text-lg text-right"
          />
          {error && <div className="text-red-500">{error}</div>}
        </div>
        <div className="grid grid-cols-4 gap-2">
          {[...Array(9)].map((_, index) => (
            <Button key={index + 1} onClick={() => handleButtonClick((index + 1).toString())}>
              {index + 1}
            </Button>
          ))}
          <Button onClick={() => handleButtonClick('0')}>0</Button>
          <Button onClick={() => handleButtonClick('+')}>+</Button>
          <Button onClick={() => handleButtonClick('-')}>-</Button>
          <Button onClick={() => handleButtonClick('*')}>*</Button>
          <Button onClick={() => handleButtonClick('/')}>/</Button>
          <Button onClick={handleCalculate} className="bg-green-500 col-span-2">=</Button>
          <Button onClick={handleClear} className="bg-red-500 col-span-2">C</Button>
          <Button onClick={handleDelete} className="bg-yellow-500">DEL</Button>
        </div>
      </div>
      <div className="sm:mt-8 lg:mt-0 w-80 h-fit">
        <h2 className="text-xl font-bold mb-2">History</h2>
        <ul className="bg-white p-5 rounded-lg shadow-lg max-h-96 overflow-auto">
          {history.map((entry, index) => (
            <HistoryEntry key={index} entry={entry} onDelete={handleItemDelete} onSave={handleItemSave} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Calculator;
