import React from "react";

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col h-screen bg-gray-100">
      {/* Header */}
      <header className="bg-blue-600 text-white p-5">
      <h2 className="text-2xl font-bold mb-5">Storage App</h2>

      <div className="absolute bg-blue-600 text-white w-fit p-5 flex top-2 right-8 rounded z-50">
        <nav className="flex space-x-8">
          <a href="/" className="hover:text-blue-200">Home</a>
          <a href="/features" className="hover:text-blue-200">Features</a>
          <a href="/about" className="hover:text-blue-200">About</a>
          <a href="/contact" className="hover:text-blue-200">Contact</a>
        </nav>
      </div>

      </header>

      {/* Main Content */}
      <main className="flex-1">{children}</main>

      {/* Footer */}
      <footer className="bg-gray-800 text-white p-5 text-center">
        <p>&copy; {new Date().getFullYear()} Your App Name. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Layout;
